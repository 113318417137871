import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import LandingPage from './components/LandingPage';
import AboutUs from './components/AboutUs';
import Branding from './components/Branding';
import GraphicDesign from './components/GraphicDesign';
import WebDesign from './components/WebDesign';
import Footer from './components/Footer';

function App() {
    return (
        <Router>
            <div>
                <Header />
                <Routes>
                    <Route path="/" element={<LandingPage />} />
                    <Route path="/about" element={<AboutUs />} />
                    <Route path="/branding" element={<Branding />} />
                    <Route path="/graphic-design" element={<GraphicDesign />} />
                    <Route path="/web-design" element={<WebDesign />} />
                </Routes>
                <Footer />
            </div>
        </Router>
    );
}

export default App;

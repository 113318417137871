import React from 'react';
import ScrollToTop from './ScrollToTop';
import './GraphicDesign.css';  // Import the CSS file for styling
import ContactForm from './ContactForm';  // Import the ContactForm component
import './global.css';  
import iceImg from '../img/illustrations/illusration_ice.png';
import bookImg from '../img/illustrations/illustration_book.png';
import whaleImg from '../img/illustrations/illustration_whales.png';
import machineImg from '../img/illustrations/illustration_machine.png';
import rafaeloImg from '../img/illustrations/illustration_rafaelo.png';
import shoesImg from '../img/illustrations/illustration_shoes.png';
import kostolImg from '../img/components/GD.jpg';
import dobryImg from '../img/graphic/dobry_good_podcast_no_background.png';
import tatrasImg from '../img/graphic/High_tatras_logo.jpg';
import cofeImg from '../img/graphic/caffe_two.jpg';
import medImg from '../img/graphic/Med_stehel_jar.jpg';
import veganImg from '../img/graphic/vegan_pizza_two.jpg';
import schneiderImg from '../img/graphic/schneider_no_background.png';


const GraphicDesign = () => {

    const scrollToProjects = () => {
        const elem = document.getElementById('projects').scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div className="graphic-design">
            <section className="graphic-design-header">
                <div className='content'>
                    <h1>Graphic design and Illustrations</h1>
                    <p>Cohesion is the name of the game when it comes to branding. It reinforces the brand identity and creates a visual impact that your customers can immediately identify with and relate to. Graphics design and Illustrations play a pivotal role in creating a lasting connection with your audience.</p>
                    <button onClick={scrollToProjects}>Explore Projects</button>
                </div>
            </section>
            <section className="design-collateral">
                <div className='content'>
                    <div className="content-text">
                        <h2>Passionate about design and illustrations</h2>
                        <p>We create compelling graphic designs and illustrations that resonate with your target audience, driving engagement and building brand loyalty. Our team of expert designers works closely with clients to understand their vision and translate it into impactful visual communications.</p>
                    </div>
                    <img src={kostolImg} alt="Design Collateral" />
                </div>
            </section>
            <section className="recent-graphic" id='projects'>
                <div className='content'>
                    <h2>Recent Graphic Design</h2>
                    <div className="graphics">
                        <div className="graphic"><img src={dobryImg} alt="Icon" /></div>
                        <div className="graphic"><img src={tatrasImg} alt="Icon" /></div>
                        <div className="graphic"><img src={cofeImg} alt="Icon" /></div>
                        <div className="graphic"><img src={medImg} alt="Icon" /></div>
                        <div className="graphic"><img src={veganImg} alt="Icon" /></div>
                        <div className="graphic"><img src={schneiderImg} alt="Icon" /></div>
                    </div>
                </div>
            </section>
            <section className="recent-projects">
    <div className='content'>
        <h2>Recent illustrations</h2>
        <div className="projects">
            <div className="project">
                <a href="https://www.instagram.com/peter_lipo_illustrations/" target="_blank" rel="noopener noreferrer">
                    <img src={iceImg} alt="Icon" />
                </a>
            </div>
            <div className="project">
                <a href="https://www.instagram.com/peter_lipo_illustrations/" target="_blank" rel="noopener noreferrer">
                    <img src={bookImg} alt="Icon" />
                </a>
            </div>
            <div className="project">
                <a href="https://www.instagram.com/peter_lipo_illustrations/" target="_blank" rel="noopener noreferrer">
                    <img src={whaleImg} alt="Icon" />
                </a>
            </div>
            <div className="project">
                <a href="https://www.instagram.com/peter_lipo_illustrations/" target="_blank" rel="noopener noreferrer">
                    <img src={machineImg} alt="Icon" />
                </a>
            </div>
            <div className="project">
                <a href="https://www.instagram.com/peter_lipo_illustrations/" target="_blank" rel="noopener noreferrer">
                    <img src={rafaeloImg} alt="Icon" />
                </a>
            </div>
            <div className="project">
                <a href="https://www.instagram.com/peter_lipo_illustrations/" target="_blank" rel="noopener noreferrer">
                    <img src={shoesImg} alt="Icon" />
                </a>
            </div>
        </div>
    </div>
</section>
            <ContactForm /> 
            <ScrollToTop />
        </div>
    );
};

export default GraphicDesign;

import React, { useEffect } from 'react';
import ScrollToTop from './ScrollToTop';
import './LandingPage.css';
import './global.css';
import ContactForm from './ContactForm';
import MovingText from './MovingText';
import backroundHead from '../img/components/backround_head.png';
import eyeImg from '../img/components/eye.png';
import handImg from '../img/components//hand-backround-2-removebg-preview.png';
import handTwoImg from '../img/components/hand-background-removebg-preview.png';
import maralu from '../img/webs/maralu.jpg'
import burnify from '../img/webs/burnify.jpg'
import krizko from '../img/graphic/Juraj_Krizko.png'
import stehel from '../img/graphic/Med_Stehel.png'
import dobry from '../img/graphic/dobry_good_podcast.jpg'
import ho from '../img/components/HO.jpg'
import { Link } from 'react-router-dom';


const LandingPage = () => {
    useEffect(() => {
        const handleMouseMove = (event) => {
            const eyes = document.querySelectorAll('.eye');
            eyes.forEach((eye) => {
                const { left, top, width, height } = eye.getBoundingClientRect();
                const eyeCenterX = left + width / 2;
                const eyeCenterY = top + height / 2;
                const angle = Math.atan2(event.clientY - eyeCenterY, event.clientX - eyeCenterX);
                const rotation = angle * (180 / Math.PI);
                eye.style.transform = `rotate(${rotation + -90}deg)`;
            });
        };

        document.addEventListener('mousemove', handleMouseMove);

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    return (
        <div>
        <div className="landing-page">
            <section className="landing-header">
                <div className='content'>
                    <div className="head move-area">
                        <img className='head' id="anchor" src={backroundHead} alt="Background Head" />
                        <img className="eye eye-left" src={eyeImg} alt="Eye Left" />
                        <img className="eye eye-right" src={eyeImg} alt="Eye Right" />
                    </div>
                    <h1>GRAPHIQ STUDIO.</h1>
                    <p>At Graphiq Studio, we create amazing graphic design, perfect logos, amazing illustrations, and great websites that stand out from the crowd.</p>
                    <Link to="/web-design"><button>Projects</button></Link>
                    <a href="#contact" ><button className='button-two'>Contact </button></a>
                    <img className='hand' src={handImg} alt="" />
                </div>
            </section>
            <section className="online-solution">
                <div className='content'>
                    <h2>Powerful and simple online solution</h2>
                    <p>We integrate with every major point of design to create an effortless online solution to grow your online brand.</p>
                    <p>Web Development</p>
                    <p>Graphic Design</p>
                </div>
            </section>
            <section className="design-point">
                <div className='content'>
                    <h2>"<span>We</span> integrate with every major point of <span>design</span> to create an effortless online <span>solution</span> to grow your online <span>brand</span>"</h2>
                    <img className='hand-two' src={handTwoImg} alt="" />
                </div>
                </section>
            <section className="industry-leaders">
                <div className='content'>
                    <h2>Trusted by our customers</h2>
                    <div className="logos">
                        <img src={maralu} alt="Client Logo 1" />
                        <img src={burnify} alt="Client Logo 1" />
                        <img src={krizko} alt="Client Logo 1" />
                        <img src={stehel} alt="Client Logo 1" />
                        <img src={dobry} alt="Client Logo 1" />
                    </div>
                </div>
            </section>
            <section className="why-work-with-us">
                <div className='content'>
                    <div className="content">
                        <div>
                        <h2>Why Work With Us?</h2>
                        <p>As a branding agency in Prague, we operate with a design-driven ethos. We are a close-knit team with a hands-on approach to crafting impactful branding and marketing solutions. We have years of experience and a strong track record with businesses of all types in Prague and across the Europe.</p>
                        <p>We’re focused on building productive relationships with our clients, so we can be at your side for any of your branding or design needs, from small ad-hoc projects to complete rebranding. From the very beginning, we take the time to listen and understand your objectives so that we can create something unique with a lasting impact.</p>
                        </div>
                        <img src={ho} alt="Our Team" />
                    </div>
                </div>
            </section>
            <MovingText />
            <section className="client-testimonials">
                <div className='content'>
                    <h2>What Our Clients Say</h2>
                    <div className="testimonials">
                        <div className="testimonial">
                            {/* <img src="path-to-client-image1.jpg" alt="Client 1" /> */}
                            <p>"Working with Graphiq studio has been fantastic. Having worked on multiple projects with the team, we could not be happier with the results and the support that we received."</p>
                            {/* <p><strong>Marketing Manager, Yomly</strong></p> */}
                        </div>
                        <div className="testimonial">
                            {/* <img src="path-to-client-image2.jpg" alt="Client 2" /> */}
                            <p>"After working with a number of designers and agencies over the years, Graphiq studio nailed it the first time. We have gone on to work with them on many projects, and they now provide us with regular design support."</p>
                            {/* <p><strong>Managing Director, AmCham Dubai</strong></p> */}
                        </div>
                        <div className="testimonial">
                            {/* <img src="path-to-client-image3.jpg" alt="Client 3" /> */}
                            <p>"The team were incredibly receptive to my ideas and a joy to work with. They shared ideas, listened, suggested changes, and were always available to update, change and adapt. I highly recommend the Graphiq studio Team."</p>
                            {/* <p><strong>Principal, Jebel Ali School</strong></p> */}
                        </div>
                    </div>
                </div>
            </section>
            <ContactForm />
            <ScrollToTop />
        </div>
        </div>
    );
};

export default LandingPage;

import React from 'react';
import './MovingText.css';
import './global.css';  

const MovingText = () => {
    return (
        <div className="moving-text-container">
            <div className="text-line line1">WE ARE THE BEST. WE ARE THE BEST. WE ARE THE BEST. WE ARE THE BEST. WE ARE THE BEST. WE ARE THE BEST.WE ARE THE BEST. WE ARE THE BEST. WE ARE THE BEST. WE ARE THE BEST. WE ARE THE BEST. WE ARE THE BEST.WE ARE THE BEST. WE ARE THE BEST. WE ARE THE BEST. WE ARE THE BEST. WE ARE THE BEST. WE ARE THE BEST.</div>
            <div className="text-line line2">WE ARE THE BEST. WE ARE THE BEST. WE ARE THE BEST. WE ARE THE BEST. WE ARE THE BEST. WE ARE THE BEST.WE ARE THE BEST. WE ARE THE BEST. WE ARE THE BEST. WE ARE THE BEST. WE ARE THE BEST. WE ARE THE BEST.WE ARE THE BEST. WE ARE THE BEST. WE ARE THE BEST. WE ARE THE BEST. WE ARE THE BEST. WE ARE THE BEST.</div>
            <div className="text-line line3">WE ARE THE BEST. WE ARE THE BEST. WE ARE THE BEST. WE ARE THE BEST. WE ARE THE BEST. WE ARE THE BEST.WE ARE THE BEST. WE ARE THE BEST. WE ARE THE BEST. WE ARE THE BEST. WE ARE THE BEST. WE ARE THE BEST.WE ARE THE BEST. WE ARE THE BEST. WE ARE THE BEST. WE ARE THE BEST. WE ARE THE BEST. WE ARE THE BEST.</div>
            <div className="text-line line4">WE ARE THE BEST. WE ARE THE BEST. WE ARE THE BEST. WE ARE THE BEST. WE ARE THE BEST. WE ARE THE BEST.WE ARE THE BEST. WE ARE THE BEST. WE ARE THE BEST. WE ARE THE BEST. WE ARE THE BEST. WE ARE THE BEST.WE ARE THE BEST. WE ARE THE BEST. WE ARE THE BEST. WE ARE THE BEST. WE ARE THE BEST. WE ARE THE BEST.</div>
        </div>
    );
};

export default MovingText;

import React from 'react';
import ScrollToTop from './ScrollToTop';
import ContactForm from './ContactForm';
import './WebDesign.css';  
import './global.css';  
import maraluImg from '../img/webs/maralu.jpg';
import burnImg from '../img/webs/burnify.jpg';
import wordpressImg from '../img/components/wordpress.png';
import squareImg from '../img/components/squarespace.png';
import htmlImg from '../img/components/htmlcssjs-overview.png';
import reactImg from '../img/components/react.png';
import fireImg from '../img/components/firebase.png';
import formulateImg from '../img/components/formulate-process.png';
import figmaImg from '../img/components/figma.jpg';
import figmaWebImg from '../img/components/figmaWeb.jpg';
import codetyping from '../img/webs/codetyping.png';


const WebDesign = () => {
 
    const scrollToContactForm = () => {
        const elem = document.getElementById('contact-form').scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div className="web-design">
            <section className="web-design-header">
            <div className='content'>
                <h1>Your preferred web design agency in Czech republic</h1>
                <p>Create an impactful online experience with Graphiq studio website design in Prague.</p>
                <button className='button-two' onClick={scrollToContactForm}>Request a Quote </button>
            </div>
            </section>
            <section className="expert-web-design">
            <div className='content'>
                <div className="content-text">
                    <h2>Expert web design that showcases your business</h2>
                    <p>We create compelling web designs that highlight your business's strengths and engage your audience. Our team of experienced designers and developers work closely with clients to ensure every website we create is tailored to their specific needs and goals.</p>
                </div>
                <div>                
                    <img src={figmaWebImg} alt="Web Design" />
                </div>
            </div>
            </section>
            <section className="diverse-clients">
            <div className='content'>
                <div>
                <h2>We serve diverse clients, crafting impactful websites</h2>
                <p>Our team of skilled designers, developers, and strategists collaborates closely with each client to understand their unique needs and goals. Whether it's a small business looking to establish an online presence or a large corporation seeking to enhance their experience, we craft tailor-made solutions that have a lasting impact.</p>
                </div>
                <div>
                <img src={figmaImg} alt="Diverse Clients" />
                </div>
            </div>
            </section>
            <section className="future-proofing">
            <div className='content'>
                <h2>Future-proofing your business with innovative web solutions</h2>
                <p>By leveraging cutting-edge technologies and industry best practices, we ensure that your online presence remains not only relevant, but also resilient in the face of evolving market dynamics. Trust us to safeguard your business's future with tailored website that stand the test of time.</p>
                <div className="future-proofing-img">
                <img src={wordpressImg} alt="Diverse Clients" />
                <img src={squareImg} alt="Diverse Clients" />
                <img src={htmlImg} alt="Diverse Clients" />
                <img src={reactImg} alt="Diverse Clients" />
                <img src={fireImg} alt="Diverse Clients" />
                </div>
            </div>
            </section>
            <section className="project-process">
            <div className='content'>
                <h2>Our website project process</h2>
                <div className="process-steps">
                    <div className="step">
                        <h3>Stage 1: Wireframing</h3>
                        <p>Preparing to lay a successful, well-thought-out website foundation is crucial. Together with you, we put the site’s main navigation and other key elements into place and develop a user journey to determine the narrative of the site.</p>
                    </div>
                    <div className="step">
                        <h3>Stage 2: Design</h3>
                        <p>We design the design look and feel of the core pages with close consultation and feedback sessions with the client. Once we have received feedback, we look to refine and design out all the elements.</p>
                    </div>
                    <div className="step">
                        <h3>Stage 3: Build</h3>
                        <p>Once the design is approved, we move to the development phase. Our in-house developers build and code the pages. We get the final content and perform on-page and technical SEO.</p>
                    </div>
                    <div className="step">
                        <h3>Stage 4: Testing & Launch</h3>
                        <p>Our thorough testing ensures the website works as intended across all devices. Once this is done, we hand it to the client for final signoff. Only once it is approved by all parties do we make it live.</p>
                    </div>
                </div>
                <button onClick={scrollToContactForm}>Request a Quote</button>
            </div>
            </section>
            <section className="frictionless-process">
            <div className='content'>
                <div>
                <h2>A frictionless process from start to finish... and beyond</h2>
                <p>From a top-notch rebrand to a full rebuild, together with your main stakeholder, we look to refine and revise your website design, from the initial wireframe to the other minor features, until graphic design is laying the exact choices. Our approach to web design is holistic – not done over a series of separate tasks – we see a single project that we can work on with you from start to finish.</p>
                <p>And when your website is up and running, we can support you to keep it fresh, original, and working hard to meet your goals.</p>
                </div>
                <div>
                <img src={formulateImg} alt="Frictionless Process" />
                </div>
            </div>
            </section>
            <section className="latest-projects">
            <div className='content'>
                <h2>Latest web projects</h2>
                <div className="projects">
                    <div className="project"><a href="https://www.maralu.sk/" target="_blank"> <img src={maraluImg} alt="Project 1" /></a></div>
                    <div className="project"><a href="https://burnify.eu/" target="_blank"><img src={burnImg} alt="Project 2" /></a></div>
                    <div className="project"><a href="https://codetyping.eu/" target="_blank"><img src={codetyping} alt="Project 2" /></a></div>
        
                </div>
                <button>See all projects</button>
            </div>
            <div id='contact-form' ></div>
            </section >
            <ContactForm /> 
            <ScrollToTop />
        </div>
    );
};

export default WebDesign;

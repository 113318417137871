import React from 'react';
import ScrollToTop from './ScrollToTop';
import './Branding.css';  // Import the CSS file for styling
import ContactForm from './ContactForm';  // Import the ContactForm component
import './global.css';  
import goodImg from '../img/graphic/dobry_good_podcast_no_background.png';
import medImg from '../img/graphic/med_stehel_no_background.png';
import cakeImg from '../img/graphic/schneider_no_background.png';
import veganImg from '../img/graphic/vegan_pizza_no_background.png';
import barberImg from '../img/graphic/Juraj_Krizko.png';
import prasoImg from '../img/graphic/el_praso.jpg';
import tomatoOne from '../img/components/frame1.jpg';
import tomatoTwo from '../img/components/frame2.jpg';

const Branding = () => {

    const scrollToContact= () => {
        const elem = document.getElementById('contact-form').scrollIntoView({ behavior: 'smooth' });
    };
    return (
        <div className="branding">
            <section className="branding-header">
                <div className='content'>
                    <h1>Crafting cohesive corporate brand identities</h1>
                    <p>Your brand needs to be consistent. A strong corporate identity gives your company credibility and helps build trust with your target audience by conveying a unified and distinctive image that resonates with your company values and mission.</p>
                    <button className='button-two' onClick={scrollToContact}>Request a Quote</button>
                </div>
            </section>
            <section className="recent-projects">
                <div className='content'>
                    <h2>Recent branding projects</h2>
                    <div className="projects">
                        <div className="project"><img src={goodImg} alt="Icon" /></div>
                        <div className="project"><img src={medImg} alt="Yomly" /></div>
                        <div className="project"><img src={cakeImg} alt="Intersec" /></div>
                        <div className="project"><img src={veganImg} alt="Branding Project 1" /></div>
                        <div className="project"><img src={barberImg} alt="Branding Project 2" /></div>
                        <div className="project"><img src={prasoImg} alt="Branding Project 3" /></div>
                    </div>
                    {/* <button className='button-two'>See all projects</button> */}
                </div>
            </section>
            <section className="what-we-do">
                <div className='content'>
                    <div className="content-text">
                        <h2>What we do</h2>
                        <p>We specialize in developing strong, cohesive branding solutions that effectively communicate your company’s values and mission. Our services include brand identity creation, logo design, and comprehensive branding strategies that ensure consistency across all your marketing materials.</p>
                    </div>
                    <img src={tomatoOne} alt="What we do" />
                </div>
            </section>
            <section className="how-we-do-it">
                <div className='content'>
                    <div className="content-text">
                        <h2>How we do it</h2>
                        <p>Our process is client-focused and collaborative. We work closely with you to understand your business, your goals, and your target audience. Through strategic planning and creative design, we develop branding solutions that resonate with your customers and differentiate you from the competition.</p>
                    </div>
                    <img src={tomatoTwo} alt="How we do it" />
                </div>
            </section>
            <section className="branding-process">
                <div className='content'>
                    <h2>Our branding process</h2>
                    <div className="process-steps">
                        <div className="step">
                            <h4>Stage 1</h4>
                            <h3>Briefing</h3>
                            <p>The foundation of any great idea. At this stage, we work one-on-one with you to ensure we have the same understanding of the project so we can get our creative juices flowing.</p>
                        </div>
                        <div className="step">
                            <h4>Stage 2</h4>
                            <h3>Research & Analysis</h3>
                            <p>We will work with any research already carried out by you and add to this to gain an understanding of the market and your competito.</p>
                        </div>
                        <div className="step">
                            <h4>Stage 3</h4>
                            <h3>Positioning</h3>
                            <p>Based on the findings from the research and analysis, we’ll look at how your brand will be positioned within the industry landscape. This phase also includes your brand’s purpose and values, if needed.</p>
                        </div>
                        <div className="step">
                            <h4>Stage 4</h4>
                            <h3>Branding Development</h3>
                            <p>Now we bring your brand to life. In presentation form, we will develop the visual identity and key messaging to showcase how your brand will look at multiple touchpoints and brand collateral.</p>
                        </div>
                        <div className="step">
                            <h4>Stage 5</h4>
                            <h3>Refinement</h3>
                            <p>Your feedback is essential for us to nail your branding. In this phase, we will incorporate rounds of feedback from your side before final sign-off and approval.</p>
                        </div>
                        <div className="step">
                            <h4>Stage 6</h4>
                            <h3>Delivery</h3>
                            <p>The day we’ve all been waiting for. Transferred to you into high-resolution format, your precious brand is now prepped and ready to be launched into the world.</p>
                        </div>
                        <div className="step">
                            <h4>Stage 7</h4>
                            <h3>Rollout</h3>
                            <p>Once the brand is finalised it’s time for our team of designers and developers to roll out your brand across collateral, touchpoints, digital platforms, and marketing materials.</p>
                        </div>
                    </div>
                </div>
                <div id='contact-form'></div>
            </section>
            <ContactForm /> 
            <ScrollToTop />
        </div>
    );
};

export default Branding;

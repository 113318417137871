import React, { useState } from 'react';
import './ContactForm.css';  // Import the CSS file for styling
import './global.css';  
import dogImg from '../img/components/dog-baground-removebg-preview.png';

const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        message: '',
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const response = await fetch('https://api.web3forms.com/submit', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                access_key: '5abeb1d5-cc6b-447f-bafb-0727f1bd9a84',  
                ...formData,
            }),
        });
        const result = await response.json();
        if (result.success) {
            alert('Form submitted successfully!');
            setFormData({ name: '', phone: '', email: '', message: '' });
        } else {
            alert('Failed to submit form. Please try again.');
        }
    };

    return (
        <section className="contact-form" id='contact'>
            <h2>Let's get your project started</h2>
            <img className='dog' src={dogImg} alt="" />
            <p>Request a Quote</p>
            <form onSubmit={handleSubmit}>
                <label>
                    Your Name*
                    <input type="text" name="name" value={formData.name} onChange={handleChange} required />
                </label>
                <label>
                    Your Phone Number
                    <input type="text" name="phone" value={formData.phone} onChange={handleChange} />
                </label>
                <label>
                    Your Email Address*
                    <input type="email" name="email" value={formData.email} onChange={handleChange} required />
                </label>
                <label>
                    Your Story*
                    <textarea name="message" value={formData.message} onChange={handleChange} required></textarea>
                </label>
                <button type="submit">Submit</button>
            </form>
        </section>
    );
};

export default ContactForm;
